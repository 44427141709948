import i18n from 'i18next'
import enUS from './en-US.json'
import ptBR from './pt-BR.json'
import momentPtBR from './date.pt-BR'
import { initReactI18next } from 'react-i18next'
import languageDetector from 'i18next-browser-languagedetector'
import moment from 'moment'

i18n
	.use(languageDetector)
	.use(initReactI18next)
	.init({
		resources: {
			'en-US': {
				translation: enUS
			},
			'pt-BR': {
				translation: ptBR
			}
		},
		fallbackLng: 'en-US',
		react: {
			bindI18n: 'languageChanged loaded',
			bindStore: 'added removed'
		},
		interpolation: {
			escapeValue: false
		}
	})
	.then(() => {
		moment.updateLocale('pt-BR', momentPtBR)
		moment.locale(i18n.language)
	})
export default i18n
