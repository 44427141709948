import { message } from 'antd'
import RootStore from 'Store/Root/RootStore'
import TeamsService from 'Persistence/Services/TeamsService'
import i18n from 'Infra/Locale/i18n'

async function getTeams() {
	const state = RootStore.getState()
	try {
		state.teams = await TeamsService.getAll()

		RootStore.notify()
	} catch (e) {
		message.error(i18n.t('Failed to get teams'))
	}
}

export default getTeams
