import {
	CollaboratorContractType,
	CollaboratorContractTypeFactory
} from 'Domain/Entities/CollaboratorContractTypes'
import BaseService from './BaseService'

export type CollaboratorContractTypeResponse = {
	id: number
	description: string
}

class CollaboratorContractTypesService {
	private static model = '/collaborators/contractTypes'

	static from(
		data: CollaboratorContractTypeResponse
	): CollaboratorContractType {
		return CollaboratorContractTypeFactory({
			key: data.id,
			value: data.description
		})
	}

	static async getAll(): Promise<CollaboratorContractType[]> {
		const response = (
			await BaseService.get<CollaboratorContractTypeResponse[], undefined>(
				`${this.model}`,
				undefined
			)
		).data

		return response.map(this.from)
	}
}

export default CollaboratorContractTypesService
