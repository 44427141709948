import axios, { AxiosResponse } from 'axios'
import moment, { Moment } from 'moment'
import LocalStorage from 'Infra/Utils/LocalStorage'
import oidc from 'Persistence/oidc'
import Replace from 'Domain/Common/Replace'

const api = axios.create({
	baseURL: `${process.env.REACT_APP_API_BASE_URL}/api`
})

api.interceptors.request.use(config => {
	const oidc = LocalStorage.getToken()
	config.headers.Authorization = `${oidc.token_type} ${oidc.access_token}`

	return config
})

api.interceptors.response.use(undefined, async error => {
	try {
		if (error.response.status === 401) {
			await oidc.signinSilent()
			const reponse = await api(error.config)
			return Promise.resolve(reponse)
		}
	} catch (e) {
		window.location.href = '/login'
		return Promise.reject(e)
	}

	return Promise.reject(error)
})

export type PrimitiveMoment<Type> = Replace<Type, Moment | undefined, string>

export function fromMoment(value: Moment) {
	return value.format('YYYY-MM-DD[T]HH:mm:ss')
}

export function toMoment(value: string) {
	return moment(value, 'YYYY-MM-DD[T]HH:mm:ss')
}

export const SortDirection = {
	ascend: 1,
	descend: 2
}

export type PaginationFilter = {
	take: number
	skip: number
}
export type OrderFilter = {
	orderBy?: string
	orderByDirection?: number
}

class BaseService {
	static post<Payload, Response>(
		modelName: string,
		data: Payload,
		route = ''
	): Promise<AxiosResponse<Response>> {
		return api.post(`${modelName}${route}`, data)
	}

	static get<Response, Query>(
		modelName: string,
		route = '',
		query?: Query,
		responseType: 'json' | 'blob' = 'json'
	): Promise<AxiosResponse<Response>> {
		return api.get(`${modelName}${route}`, {
			params: query,
			responseType: responseType
		})
	}

	static put<Payload, Response>(
		modelName: string,
		data: Payload,
		route = ''
	): Promise<AxiosResponse<Response>> {
		return api.put(`${modelName}${route}`, data)
	}

	static remove<Payload, Response>(
		modelName: string,
		data: Payload,
		route = ''
	): Promise<AxiosResponse<Response>> {
		return api.delete(`${modelName}${route}`, { data })
	}
}

export default BaseService
