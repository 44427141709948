import Currency, { CurrencyFactory } from 'Domain/Entities/Expenses/Currency'
import BaseService, { OrderFilter } from 'Persistence/Services/BaseService'

export type ResponseCurrency = {
	id: number
	symbol: string
	name?: string
	code: string
}

type GetCurrenciesResponse = {
	results: ResponseCurrency[]
	totalResults: number
}

type PayloadCurrency = {
	symbol: string
	name: string
	code: string
}

type Filters = {
	search?: string
} & {
	take?: number
	skip?: number
} & OrderFilter

class CurrencyService {
	private static model = '/expenses/currency'

	static from(data: ResponseCurrency): Currency {
		const currency = CurrencyFactory({
			key: data.id,
			value: data.name,
			symbol: data.symbol,
			code: data.code
		})

		return currency
	}

	static into(data: Currency): PayloadCurrency {
		return {
			symbol: data.symbol.trim(),
			name: data.value.trim(),
			code: data.code.trim()
		}
	}

	static async add(currency: Currency): Promise<void> {
		try {
			await BaseService.post<PayloadCurrency, void>(
				this.model,
				this.into(currency)
			)
		} catch (err) {
			throw err.response
		}
	}

	static async get(id: number): Promise<Currency> {
		const response = (
			await BaseService.get<ResponseCurrency, void>(this.model, `/${id}`)
		).data

		return this.from(response)
	}

	static async getAll(filters?: Filters) {
		const response = (
			await BaseService.get<GetCurrenciesResponse, Filters>(
				this.model,
				undefined,
				filters
			)
		).data
		return {
			totalResults: response.totalResults,
			data: response.results.map(this.from)
		}
	}

	static async update(currency: Currency): Promise<Currency> {
		try {
			const response = (
				await BaseService.put<PayloadCurrency, ResponseCurrency>(
					this.model,
					this.into(currency),
					`/${currency.key}`
				)
			).data

			return this.from(response)
		} catch (err) {
			throw err.response
		}
	}

	static async delete(id: number): Promise<void> {
		try {
			await BaseService.remove(this.model, null, `/${id}`)
		} catch (err) {
			throw err.response
		}
	}
}

export default CurrencyService
