import IListable from 'Domain/Common/IListable'

class Team implements IListable {
	constructor(
		public key: number,
		public value: string,
		public isActive: boolean
	) {}
}

export function TeamFactory(obj: Partial<Team>): Team {
	return new Team(obj.key || 0, obj.value || '', obj.isActive || false)
}

export default Team
