import React from 'react'

// Styles
import 'antd/dist/antd.css'
import './Styles/general.css'

// Translation
import 'Infra/Locale/i18n'

//Components
import { BrowserRouter, Switch } from 'react-router-dom'
import PrivateApp from './Client/PrivateApp'
import PostLogin from './Client/PostLogin'
import SilentRedirect from './Client/SilentRedirect'
import Login from './Client/Login'
import LazyRoute from 'Infra/Navigation/LazyRoute'
import Logout from './Client/Logout'

function PublicApp() {
	return (
		<BrowserRouter>
			<React.Suspense fallback={<></>}>
				<Switch>
					<LazyRoute path="/login" component={Login} />
					<LazyRoute path="/post-login" component={PostLogin} />
					<LazyRoute path="/silent-redirect" component={SilentRedirect} />
					<LazyRoute path="/logout" component={Logout} />
					<LazyRoute path="/" component={PrivateApp} />
				</Switch>
			</React.Suspense>
		</BrowserRouter>
	)
}

export default PublicApp
