import IListable from 'Domain/Common/IListable'
import Collaborator from './Collaborator'
import { Office } from './Office'

export class Department implements IListable {
	constructor(public key: number, public value: string) {}
}

export function DepartmentFactory(obj: Partial<Department>): Department {
	return new Department(obj.key || 0, obj.value || '')
}

export type JobInfo = {
	manager?: Collaborator
	secondaryReviewer?: Collaborator
	title?: string
	location?: Office
	department?: Department
}
