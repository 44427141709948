import IListable from 'Domain/Common/IListable'

class ProfileType implements IListable {
	constructor(
		public key: number,
		public value: string,
		public isActive: boolean
	) {}
}

export function ProfileTypeFactory(obj: Partial<ProfileType>): ProfileType {
	return new ProfileType(obj.key || 0, obj.value || '', obj.isActive || false)
}

export enum ProfileTypes {
	ADMIN = 1,
	HR = 2,
	COLLABORATOR = 3,
	USER = 4
}

export const profileTypes: ProfileType[] = [
	{ key: ProfileTypes.ADMIN, value: 'Administrator', isActive: true },
	{ key: ProfileTypes.HR, value: 'Human Resources', isActive: true },
	{ key: ProfileTypes.COLLABORATOR, value: 'Collaborator', isActive: true },
	{ key: ProfileTypes.USER, value: 'User', isActive: true }
]

export default ProfileType
