export type Role = {
	id: number
	name: string
}
class User {
	constructor(
		public id: number,
		public key: number,
		public name: string,
		public email: string,
		public profilePictureUrl: string,
		public roles: Role[]
	) {}
}

export function UserFactory(obj: Partial<User>): User {
	return new User(
		obj.id || 0,
		obj.key || 0,
		obj.name || '',
		obj.email || '',
		obj.profilePictureUrl || '',
		obj.roles || []
	)
}

export default User
