import { City } from 'Domain/Entities/Location/City'
import { Office, OfficeFactory } from 'Domain/Entities/Office'
import BaseService from './BaseService'

export type CollaboratorContractLocationResponse = {
	id: number
	description: string
	cities: City
}

class CollaboratorContractLocationService {
	private static model = '/collaborators/contractLocations'

	static from(data: CollaboratorContractLocationResponse): Office {
		return OfficeFactory({
			key: data.id,
			value: data.description,
			city: data.cities
		})
	}

	static async getAll(): Promise<Office[]> {
		const response = (
			await BaseService.get<CollaboratorContractLocationResponse[], undefined>(
				`${this.model}`,
				undefined
			)
		).data

		return response.map(this.from)
	}
}

export default CollaboratorContractLocationService
