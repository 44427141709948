import IListable from 'Domain/Common/IListable'
import Incomplete from 'Domain/Common/Incomplete'
import { SkillType, skillTypes } from './SkillType'

export class Skill implements IListable {
	constructor(
		public key: number,
		public value: string,
		public type: SkillType
	) {}
}

export function SkillFactory(obj: Partial<Skill>): Skill {
	return new Skill(obj.key || 0, obj.value || '', obj.type || skillTypes[0])
}

export function IncompleteSkillFactory(obj: Partial<Skill>): Incomplete<Skill> {
	obj.key = undefined
	obj.value = undefined
	obj.type = undefined
	return Object.setPrototypeOf(obj, Skill.prototype)
}
