import { City, CityFactory } from 'Domain/Entities/Location/City'
import BaseService from '../BaseService'
import StateService, { ResponseState } from './StateService'

export type ResponseCity = {
	id: number
	name: string
	state: ResponseState
}

type Filter = {
	name?: string
	stateId?: number
}

class CityService {
	private static model = '/locations/cities'

	static from(data: ResponseCity): City {
		return CityFactory({
			key: data.id,
			value: data.name,
			state: data.state ? StateService.from(data.state) : undefined
		})
	}

	static async getAll(filter: Filter): Promise<City[]> {
		const response = (
			await BaseService.get<ResponseCity[], Filter>(
				this.model,
				undefined,
				filter
			)
		).data

		return response.map(this.from)
	}
}

export default CityService
