import IListable from 'Domain/Common/IListable'
import Incomplete from 'Domain/Common/Incomplete'
import moment, { Moment } from 'moment'
import {
	CollaboratorContractType,
	CollaboratorContractTypeFactory
} from './CollaboratorContractTypes'
import { Office, OfficeFactory } from './Office'

export const contractTypes: IListable[] = [
	{ key: 1, value: 'CLT' },
	{ key: 2, value: 'PJ' },
	{ key: 3, value: 'Estagiário' }
]

class CollaboratorContract {
	constructor(
		public key: number,
		public startDate: Moment,
		public workload: number,
		public type: CollaboratorContractType,
		public location: Office,
		public endDate?: Moment
	) {}
}

export function CollaboratorContractFactory(
	obj: Partial<CollaboratorContract>
): CollaboratorContract {
	return new CollaboratorContract(
		obj.key || 0,
		obj.startDate || moment(),
		obj.workload || 0,
		obj.type || CollaboratorContractTypeFactory({}),
		obj.location || OfficeFactory({}),
		obj.endDate
	)
}

export function IncompleteCollaboratorContractFactory(
	obj: Partial<CollaboratorContract>
): Incomplete<CollaboratorContract> {
	obj.key = undefined
	obj.startDate = undefined
	obj.endDate = undefined
	obj.workload = undefined
	obj.type = undefined
	obj.location = undefined

	return Object.setPrototypeOf(obj, CollaboratorContract.prototype)
}

export default CollaboratorContract
