import React from 'react'
import { Route } from 'react-router-dom'
import { IRoute } from './Router'
import PageTransition from './PageTransition'

function LazyRoute(props: IRoute) {
	return (
		<Route
			path={props.path}
			exact={props.exact}
			render={() => (
				<React.Suspense fallback={<PageTransition />}>
					<div style={{ animation: 'fadein 0.3s' }}>
						<props.component />
					</div>
				</React.Suspense>
			)}
		/>
	)
}
export default LazyRoute
