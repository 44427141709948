import RootStore from 'Store/Root/RootStore'
import CollaboratorService from 'Persistence/Services/CollaboratorService'
import checkRegisterPermission, {
	openContract
} from './checkRegisterPermission'

async function getSelf() {
	const state = RootStore.getState()

	try {
		const collaborator = await CollaboratorService.getSelf()

		state.self = collaborator
		state.hasCollaborator = true

		const contract = openContract(collaborator.contracts || [])
		state.hasActiveContract = contract != undefined
		state.activeContractType = contract?.type.key ?? 0

		state.hasRegisterPermission = checkRegisterPermission(
			state.hasActiveContract,
			collaborator.allowTimeRegistration,
			state.activeContractType
		)

		RootStore.notify()
	} catch (e) {
		console.error(e)
	}
}

export default getSelf
