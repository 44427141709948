import React from 'react'
import oidc from 'Persistence/oidc'
import { Redirect } from 'react-router-dom'

const PostLogin = React.lazy(async () => {
	const user = await oidc.signinRedirectCallback()
	oidc.storeUser(user)

	return { default: () => <Redirect to="/" /> }
})

export default PostLogin
