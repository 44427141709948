import IListable from 'Domain/Common/IListable'
import Incomplete from 'Domain/Common/Incomplete'

class Currency implements IListable {
	constructor(
		public key: number,
		public value: string,
		public symbol: string,
		public code: string
	) {}
}

export function CurrencyFactory(obj: Partial<Currency>) {
	return new Currency(
		obj.key || 0,
		obj.value || '',
		obj.symbol || '',
		obj.code || ''
	)
}

export function IncompleteCurrencyFactory(
	obj: Partial<Currency>
): Incomplete<Currency> {
	obj.key = undefined
	obj.value = undefined
	obj.symbol = undefined
	obj.code = undefined
	return Object.setPrototypeOf(obj, Currency.prototype)
}

export default Currency
