import BaseService, { toMoment, fromMoment, OrderFilter } from './BaseService'
import Collaborator, { CollaboratorFactory } from 'Domain/Entities/Collaborator'
import ProfileType from 'Domain/Entities/ProfileType'
import Team from 'Domain/Entities/Team'
import { Department } from 'Domain/Entities/JobInfo'
import { CollaboratorPaginated } from 'Store/Management/ManagementStore'
import formatPIS from 'Infra/Format/PIS'
import OfficeService, { ResponseOffice } from './OfficeService'
import CollaboratorContractsService, {
	ResponseCollaboratorContract
} from './CollaboratorContractsService'
import { DocumentTypes } from 'Domain/Entities/CollaboratorDocumentTypes'
import { DeskFactory } from 'Domain/Entities/Desk'
import { FloorFactory } from 'Domain/Entities/Floor'
import { PontomaisBankSummary } from 'Domain/Entities/Timetek/PontomaisBankSummary'
import CollaboratorMacAddress from 'Domain/Entities/CollaboratorMacAddress'

export type ResponseCollaborator = {
	id: number
	firstName: string
	lastName: string
	preferredName?: string
	title?: string
	professionalEmail: string
	personalEmail?: string
	pis?: number
	birthDate: string
	profile: {
		id: number
		name: string
		icon: string
		isActive: boolean
	}
	team?: {
		id: number
		name: string
	}
	location?: ResponseOffice
	detail?: {
		manager?: {
			id: number
			firstName: string
			lastName: string
			professionalEmail: string
		}
		secondaryReviewer?: {
			id: number
			firstName: string
			lastName: string
		}
		desk?: {
			id: number
			description: string
			floor: {
				id: number
				description: string
				officeLocation?: ResponseOffice
				desks: []
			}
		}
	}
	macAddresses?: CollaboratorMacAddress[]
	department?: {
		id: number
		name: string
	}
	isActive: boolean
	allowTimeRegistration: boolean
	profilePictureUrl?: string
	contracts?: ResponseCollaboratorContract[]
	fullName?: string
	userId?: number
	documentType?: number
	documentNumber?: string
}

type PayloadCollaborator = {
	id: number
	firstName: string
	lastName: string
	preferredName?: string
	title?: string
	professionalEmail: string
	personalEmail?: string
	pis?: string
	birthDate?: string
	profile: number
	team?: number
	location?: number
	department?: number
	manager?: number
	secondaryReviewer?: number
	profilePictureUrl?: string
	isActive?: boolean
	allowTimeRegistration?: boolean
	documentType?: number
	documentNumber?: string
	deskId?: number | null
}

type PayloadCollaboratorSelf = {
	deskId: number | null
}

type Filters = {
	profile?: number
	isActive?: boolean
	searchTerm?: string
	office?: number
	take?: number
	skip?: number
} & OrderFilter

type GetCollaboratorsResponse = {
	results: ResponseCollaborator[]
	totalResults: number
}

class CollaboratorService {
	private static model = '/collaborators'

	static from(data: ResponseCollaborator): Collaborator {
		return CollaboratorFactory({
			id: data.id,
			key: data.id,
			firstName: data.firstName,
			lastName: data.lastName,
			birthDate: toMoment(data.birthDate),
			professionalEmail: data.professionalEmail,
			personalEmail: data.personalEmail,
			isActive: data.isActive,
			allowTimeRegistration: data.allowTimeRegistration,
			pis: data.pis ? formatPIS(data.pis) : undefined,
			preferredName: data.preferredName,
			profile: new ProfileType(
				data.profile?.id,
				data.profile?.name,
				data.profile?.isActive
			),
			team: data.team
				? new Team(data.team.id, data.team.name, true)
				: undefined,
			department: data.department
				? new Department(data.department.id, data.department.name)
				: undefined,
			location: data.location ? OfficeService.from(data.location) : undefined,
			manager: data.detail?.manager
				? CollaboratorFactory({
						key: data.detail.manager.id,
						firstName: data.detail.manager.firstName,
						lastName: data.detail.manager.lastName,
						professionalEmail: data.detail.manager.professionalEmail
				  })
				: undefined,
			secondaryReviewer: data.detail?.secondaryReviewer
				? CollaboratorFactory({
						key: data.detail.secondaryReviewer.id,
						firstName: data.detail.secondaryReviewer.firstName,
						lastName: data.detail.secondaryReviewer.lastName
				  })
				: undefined,
			title: data.title,
			contracts: (data.contracts || []).map(CollaboratorContractsService.from),
			macAddresses: data.macAddresses || [],
			fullName: data.fullName,
			userId: data.userId,
			documentType: DocumentTypes.find(
				documentType => documentType.key == data.documentType
			),
			documentNumber: data.documentNumber,
			desk: DeskFactory({
				key: data.detail?.desk?.id,
				value: data.detail?.desk?.description,
				floor: FloorFactory({
					key: data.detail?.desk?.floor.id,
					value: data.detail?.desk?.floor.description
				})
			})
		})
	}

	static into(data: Collaborator): PayloadCollaborator {
		return {
			id: data.key,
			birthDate: data.birthDate?.isValid()
				? fromMoment(data.birthDate)
				: undefined,
			profile: data.profile.key,
			team: data.team?.key,
			professionalEmail: data.professionalEmail,
			personalEmail: data.personalEmail,
			firstName: data.firstName,
			lastName: data.lastName,
			preferredName: data.preferredName,
			department: data.department?.key,
			location: data.location?.key,
			manager: data.manager?.key,
			secondaryReviewer: data.secondaryReviewer?.key,
			title: data.title,
			pis: data.pis?.replace(/\D/g, ''),
			isActive: data.isActive,
			allowTimeRegistration: data.allowTimeRegistration,
			documentType: data.documentType?.key,
			documentNumber: data.documentNumber,
			deskId: data.desk?.key == 0 ? null : data.desk?.key ?? null
		}
	}

	static async getAll(filters?: Filters): Promise<CollaboratorPaginated> {
		const response = (
			await BaseService.get<GetCollaboratorsResponse, Filters>(
				this.model,
				undefined,
				filters
			)
		).data

		return {
			totalResults: response.totalResults,
			collaborators: response.results.map(this.from)
		}
	}

	static async getAllWithUserId(filters?: Filters): Promise<Collaborator[]> {
		const response = (
			await BaseService.get<ResponseCollaborator[], Filters>(
				this.model,
				'/userIds',
				filters
			)
		).data

		return response.map(this.from)
	}

	static async get(id: number): Promise<Collaborator> {
		const response = await BaseService.get<ResponseCollaborator, void>(
			this.model,
			`/${id}`
		)

		return this.from(response.data)
	}

	static async getPontomaisBankSumary(): Promise<PontomaisBankSummary[]> {
		const response = await BaseService.get<PontomaisBankSummary[], null>(
			this.model,
			`/timebalance`
		)

		return response.data
	}

	static async update(collaborator: Collaborator): Promise<Collaborator> {
		const response = (
			await BaseService.put<PayloadCollaborator, ResponseCollaborator>(
				this.model,
				this.into(collaborator),
				`/${collaborator.key}`
			)
		).data

		return this.from(response)
	}

	static async getSelf(): Promise<Collaborator> {
		const response = await BaseService.get<ResponseCollaborator, null>(
			this.model,
			'/Self'
		)

		return this.from(response.data)
	}

	static async updateSelf(collaborator: Collaborator): Promise<Collaborator> {
		try {
			const payload: PayloadCollaboratorSelf = {
				deskId:
					collaborator.desk?.key == 0 ? null : collaborator.desk?.key ?? null
			}

			const response = await BaseService.put<
				PayloadCollaboratorSelf,
				ResponseCollaborator
			>(this.model, payload, '/Self')

			return this.from(response.data)
		} catch (error) {
			throw error.response
		}
	}

	static async add(collaborator: Collaborator): Promise<number> {
		try {
			const response = await BaseService.post<
				PayloadCollaborator,
				ResponseCollaborator
			>(this.model, this.into(collaborator))
			return response.data.id
		} catch (error) {
			throw error.response
		}
	}
}
export default CollaboratorService
