import BaseService from './BaseService'
import { Skill, SkillFactory } from 'Domain/Entities/Skills/Skill'
import { skillTypes } from 'Domain/Entities/Skills/SkillType'
import Skills from 'Domain/Entities/Skills/Skills'

type SkillPayload = {
	name: string
	type: string
}
export type SkillResponse = {
	id: number
	name: string
	type: string
}
type SkillsResponse = {
	languageSkills: SkillResponse[]
	softSkills: SkillResponse[]
	technicalSkills: SkillResponse[]
}

class SkillService {
	private static model = '/skills'

	static into(data: Skill): SkillPayload {
		return {
			name: data.value,
			type: data.type.value
		}
	}

	static async add(skill: Skill): Promise<void> {
		await BaseService.post<SkillPayload, void>(this.model, this.into(skill))
	}

	static from(skill: SkillResponse): Skill {
		return SkillFactory({
			key: skill.id,
			value: skill.name,
			type: skillTypes.find(type => type.value === skill.type)
		})
	}

	static async getAll(): Promise<Skills> {
		const response = (await BaseService.get<SkillsResponse, void>(this.model))
			.data
		return {
			technicalSkills: response.technicalSkills.map(skill => this.from(skill)),
			softSkills: response.softSkills.map(skill => this.from(skill)),
			languageSkills: response.languageSkills.map(skill => this.from(skill))
		}
	}

	static async update(id: number, skill: Skill): Promise<void> {
		await BaseService.put<SkillPayload, void>(
			this.model,
			this.into(skill),
			`/${id}`
		)
	}

	static async delete(skill: Skill): Promise<void> {
		await BaseService.remove(this.model, null, `/${skill.key}`)
	}
}

export default SkillService
