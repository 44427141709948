import IListable from 'Domain/Common/IListable'
import Incomplete from 'Domain/Common/Incomplete'

import Floor, { FloorFactory } from './Floor'

export default class Desk implements IListable {
	constructor(public key: number, public value: string, public floor: Floor) {}
}

export function DeskFactory(obj: Partial<Desk>): Desk {
	return new Desk(obj.key || 0, obj.value || '', obj.floor || FloorFactory({}))
}

export function IncompleteDeskFactory(obj: Partial<Desk>): Incomplete<Desk> {
	obj.key = undefined
	obj.value = undefined
	obj.floor = undefined
	return Object.setPrototypeOf(obj, Desk.prototype)
}
