import IListable from 'Domain/Common/IListable'
import { Country, CountryFactory } from './Country'

export class State implements IListable {
	constructor(
		public key: number,
		public value: string,
		public abbreviation: string,
		public country: Country
	) {}
}

export function StateFactory(obj: Partial<State>): State {
	return new State(
		obj.key || 0,
		obj.value || '',
		obj.abbreviation || '',
		obj.country || CountryFactory({})
	)
}
