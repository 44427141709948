import IListable from 'Domain/Common/IListable'
import Incomplete from 'Domain/Common/Incomplete'
import Currency from './Expenses/Currency'
import { City, CityFactory } from './Location/City'

export class Office implements IListable {
	constructor(
		public key: number,
		public value: string,
		public city: City,
		public currency?: Currency
	) {}
}

export function OfficeFactory(obj: Partial<Office>): Office {
	return new Office(
		obj.key || 0,
		obj.value || '',
		obj.city || CityFactory({}),
		obj.currency || undefined
	)
}

export function IncompleteOfficeFactory(
	obj: Partial<Office>
): Incomplete<Office> {
	obj.key = undefined
	obj.value = undefined
	obj.city = undefined
	obj.currency = undefined
	return Object.setPrototypeOf(obj, Office.prototype)
}
