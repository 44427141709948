import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`
const dash = keyframes`
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
`
const color = keyframes`
  100%,
  0% {
    stroke: #00578A;
  }
  50% {
    stroke: #8CC135;
  }
`

type Props = { size: number }

export const LoaderContainer = styled.div<Props>`
	margin: auto;
	width: ${({ size }) => size}px;
	height: ${({ size }) => size}px;

	& svg {
		animation: ${rotate} 2s linear infinite;

		& circle {
			stroke-dasharray: 1, 200;
			stroke-dashoffset: 0;
			animation: ${dash} 1.5s ease-in-out infinite,
				${color} 3s ease-in-out infinite;
			stroke-linecap: round;
		}
	}
`
