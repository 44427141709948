import Oidc from 'oidc-client'

const state = new Oidc.WebStorageStateStore({})

const oidc = new Oidc.UserManager({
	authority: process.env.REACT_APP_API_IDENTITY_SERVER,
	client_id: 'js-frontend',
	redirect_uri: `${process.env.REACT_APP_REDIRECT_URL}/post-login`,
	post_logout_redirect_uri: `${process.env.REACT_APP_REDIRECT_URL}/login`,
	response_type: 'code',
	scope: 'openid hub hub.api offline_access',
	userStore: state,
	silent_redirect_uri: `${process.env.REACT_APP_REDIRECT_URL}/silent-redirect`,
	automaticSilentRenew: true
})

oidc.events.addAccessTokenExpiring(() => {
	console.log('Token is about to expire, calling silent renewal...')
	oidc.signinSilent()
})

oidc.events.addSilentRenewError(error =>
	console.log('Unable to renew token: ', error)
)

export default oidc
