import { State, StateFactory } from 'Domain/Entities/Location/State'
import BaseService from '../BaseService'
import CountryService, { ResponseCountry } from './CountryService'

export type ResponseState = {
	id: number
	name: string
	abbreviation: string
	country: ResponseCountry
}

type CountryFilter = {
	countryId: number
}

type Filter = {
	name?: string
	countryId?: number
}

class StateService {
	private static model = '/locations/states'

	static from(data: ResponseState): State {
		return StateFactory({
			key: data.id,
			value: data.name,
			abbreviation: data.abbreviation,
			country: data.country ? CountryService.from(data.country) : undefined
		})
	}

	static async getAll(filter: Filter): Promise<State[]> {
		const response = (
			await BaseService.get<ResponseState[], Filter>(
				this.model,
				undefined,
				filter
			)
		).data

		return response.map(this.from)
	}
}

export default StateService
