import React from 'react'
import oidc from 'Persistence/oidc'
import { useTranslation } from 'react-i18next'

function Logout() {
	const { t } = useTranslation()

	oidc.signoutRedirect()

	return <h1 className="signing-text">{t('Signing out')}...</h1>
}

export default Logout
