import Team from 'Domain/Entities/Team'
import Collaborator, { CollaboratorFactory } from 'Domain/Entities/Collaborator'
import Store from 'Store/Store'
import User, { UserFactory } from 'Domain/Entities/User'
import { Skill } from 'Domain/Entities/Skills/Skill'
import { SnackbarMessage } from 'Web/Components/SnackbarMessage/SnackbarMessages'
import { SkillRankingData } from '../../Persistence/Services/SkillRankingService'

export class Root {
	constructor(
		public self: Collaborator = CollaboratorFactory({}),
		public user: User = UserFactory({}),
		public hasRegisterPermission: boolean = false,
		public hasCollaborator: boolean = false,
		public hasActiveContract: boolean = false,
		public activeContractType: number = 0,
		public teams: Team[] = [],
		public languages: Skill[] = [],
		public softSkills: Skill[] = [],
		public technologies: Skill[] = [],
		public language: string = '',
		public message?: SnackbarMessage,
		public rankLanguages: SkillRankingData[] = [],
		public rankSoft: SkillRankingData[] = [],
		public rankTechnologies: SkillRankingData[] = []
	) {}
}

const RootStore = new Store(new Root())

export default RootStore
