function register() {
	const isProduction = process.env.NODE_ENV === 'production'
	const hasServiceWorkerAvailableInBrowser = 'serviceWorker' in navigator

	if (!isProduction || !hasServiceWorkerAvailableInBrowser) {
		return
	}

	window.addEventListener('load', () => {
		// Create React App generates a service-worker that uses workbox and already follow the best practices
		// To use it, is just a matter of registering.
		const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`
		navigator.serviceWorker.register(swUrl).catch(err => {
			console.error(`Error during service worker registration: ${err}`)
		})
	})
}

export default register
