import { Country, CountryFactory } from 'Domain/Entities/Location/Country'
import BaseService from '../BaseService'

export type ResponseCountry = {
	id: number
	name: string
}

class CountryService {
	private static model = '/locations/countries'

	static from(data: ResponseCountry): Country {
		return CountryFactory({
			key: data.id,
			value: data.name
		})
	}

	static async getAll(name?: string): Promise<Country[]> {
		const response = (
			await BaseService.get<ResponseCountry[], { name?: string }>(
				this.model,
				undefined,
				{ name }
			)
		).data

		return response.map(this.from)
	}
}

export default CountryService
