import React from 'react'
import { LoaderContainer } from './style'

export const DEFAULT_LOADER_SIZE = 40

type Props = {
	size?: number
	style?: React.CSSProperties
}

function Loader(props: Props) {
	return (
		<LoaderContainer
			size={props.size || DEFAULT_LOADER_SIZE}
			style={props.style}
		>
			<svg viewBox="25 25 50 50">
				<circle
					r="20"
					cx="50"
					cy="50"
					fill="none"
					strokeWidth="4"
					strokeMiterlimit="10"
				/>
			</svg>
		</LoaderContainer>
	)
}

export default Loader
