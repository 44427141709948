import CollaboratorContract from 'Domain/Entities/CollaboratorContract'
import { CollaboratorContractTypes } from 'Domain/Entities/CollaboratorContractTypes'
import moment from 'moment'

export function openContract(contracts: CollaboratorContract[]) {
	const today = moment()
	const isCurrentContract = (contract: CollaboratorContract) => {
		if (!contract.endDate) return today.isAfter(contract.startDate)
		const endDate = contract.endDate.add(1, 'day')
		return today.isBetween(contract.startDate, endDate)
	}

	return contracts.find(isCurrentContract)
}

function checkRegisterPermission(
	hasActiveContract: boolean,
	allowTimeRegistration: boolean,
	contractType: number
): boolean {
	if (!hasActiveContract || contractType === CollaboratorContractTypes.PJ)
		return false

	return allowTimeRegistration
}

export default checkRegisterPermission
