import IListable from 'Domain/Common/IListable'
import Incomplete from 'Domain/Common/Incomplete'
import { State, StateFactory } from './State'

export class City implements IListable {
	constructor(public key: number, public value: string, public state: State) {}
}

export function CityFactory(obj: Partial<City>): City {
	return new City(obj.key || 0, obj.value || '', obj.state || StateFactory({}))
}

export function IncompleteCityFactory(obj: Partial<City>): Incomplete<City> {
	obj.key = undefined
	obj.value = undefined
	obj.state = undefined
	return Object.setPrototypeOf(obj, City.prototype)
}
