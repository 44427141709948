import Currency, { CurrencyFactory } from 'Domain/Entities/Expenses/Currency'
import { CityFactory } from 'Domain/Entities/Location/City'
import { Office, OfficeFactory } from 'Domain/Entities/Office'
import BaseService, { OrderFilter, PaginationFilter } from './BaseService'
import CurrencyService, { ResponseCurrency } from './Expenses/CurrencyService'
import CityService, { ResponseCity } from './Location/CityService'

export type ResponseOffice = {
	id: number
	name: string
	city: ResponseCity
	currency: ResponseCurrency
}

export type PayloadOffice = {
	name: string
	cityId: number
	currencyId?: number
}

type Filters = {
	key?: number
	value?: string
	searchTerm?: string
} & PaginationFilter &
	OrderFilter

class OfficeService {
	private static model = 'office-locations'

	static from(data: ResponseOffice): Office {
		return OfficeFactory({
			key: data.id,
			value: data.name,
			city: data.city ? CityService.from(data.city) : CityFactory({}),
			currency: data.currency ? CurrencyService.from(data.currency) : undefined
		})
	}

	static into(data: Office): PayloadOffice {
		return {
			name: data.value,
			cityId: data.city.key,
			currencyId: data.currency?.key
		}
	}

	static async getAll(): Promise<Office[]> {
		const response = (
			await BaseService.get<ResponseOffice[], Filters>(this.model)
		).data

		return response.map(this.from)
	}

	static async add(data: Office): Promise<void> {
		await BaseService.post<PayloadOffice, void>(this.model, this.into(data))
	}

	static async update(data: Office): Promise<Office> {
		const response = (
			await BaseService.put<PayloadOffice, ResponseOffice>(
				this.model,
				this.into(data),
				`/${data.key}`
			)
		).data

		return response ? data : OfficeFactory({}) //this.from(response)
	}
}

export default OfficeService
