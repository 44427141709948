type LanguageSkill = { key: 1; value: 'LanguageSkill' }
type TechnicalSkill = { key: 2; value: 'TechnicalSkill' }
type SoftSkill = { key: 3; value: 'SoftSkill' }

export type SkillType = LanguageSkill | TechnicalSkill | SoftSkill

export const skillTypes: SkillType[] = [
	{ key: 1, value: 'LanguageSkill' },
	{ key: 2, value: 'TechnicalSkill' },
	{ key: 3, value: 'SoftSkill' }
]
