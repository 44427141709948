import IListable from 'Domain/Common/IListable'

export class CollaboratorContractType implements IListable {
	constructor(public key: number, public value: string) {}
}

export function CollaboratorContractTypeFactory(
	obj: Partial<CollaboratorContractType>
): CollaboratorContractType {
	return new CollaboratorContractType(obj.key || 0, obj.value || '')
}

export enum CollaboratorContractTypes {
	CLT = 1,
	PJ = 2,
	Intern = 3
}

export const defaultCollaboratorContractType = CollaboratorContractTypes.CLT
