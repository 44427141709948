import { message } from 'antd'
import RootStore from 'Store/Root/RootStore'
import SkillService from 'Persistence/Services/SkillService'
import i18n from 'Infra/Locale/i18n'

async function getSkills() {
	const state = RootStore.getState()

	try {
		const groupedSkills = await SkillService.getAll()

		state.languages = groupedSkills.languageSkills
		state.softSkills = groupedSkills.softSkills
		state.technologies = groupedSkills.technicalSkills

		RootStore.notify()
	} catch (e) {
		message.error(i18n.t('Failed to get skills'))
	}
}

export default getSkills
