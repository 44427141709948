import getTeams from 'Store/Root/getTeams'
import getUser from 'Store/Root/getUser'
import getSkills from 'Store/Root/getSkills'
import RootStore from 'Store/Root/RootStore'
import getSelf from './getSelf'

async function beforeRootLoad() {
	await Promise.all([getUser(), getSelf()])

	const state = RootStore.getState()
	if (state.hasCollaborator) {
		await Promise.all([getSkills(), getTeams()])
	}
}

export default beforeRootLoad
