import BaseService from './BaseService'
import User, { UserFactory } from 'Domain/Entities/User'

type ResponseGetSelf = {
	id: number
	name: string
	email: string
	profilePictureUrl?: string
	roles: [{ id: number; name: string }]
}
class UserService {
	private static model = '/users'

	static from(data: ResponseGetSelf): User {
		return UserFactory({
			...data,
			key: data.id
		})
	}

	static async getSelf(): Promise<User> {
		const result = (
			await BaseService.get<ResponseGetSelf, null>(this.model, '/Self')
		).data

		result.profilePictureUrl = await this.getProfileImage()

		return this.from(result)
	}

	static async getProfileImage(): Promise<string | undefined> {
		const response = await BaseService.get(
			this.model,
			`/self/ProfilePicture`,
			null,
			'blob'
		)
		if (response.status === 200) {
			return URL.createObjectURL(response.data)
		}
		return
	}

	static async getProfileImageForUser(
		userId: number
	): Promise<string | undefined> {
		const response = await BaseService.get(
			this.model,
			`/${userId}/ProfilePicture`,
			null,
			'blob'
		)
		if (response.status === 200) {
			return URL.createObjectURL(response.data)
		}
		return
	}
}

export default UserService
