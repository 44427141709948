import { message } from 'antd'
import RootStore from 'Store/Root/RootStore'
import UserService from 'Persistence/Services/UserService'
import i18n from 'Infra/Locale/i18n'

async function getUser() {
	const state = RootStore.getState()
	try {
		state.user = await UserService.getSelf()
		RootStore.notify()
	} catch (e) {
		message.error(i18n.t('Failed to get user'))
	}
}

export default getUser
