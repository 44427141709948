import CollaboratorContract, {
	CollaboratorContractFactory
} from 'Domain/Entities/CollaboratorContract'
import moment from 'moment'
import BaseService, { fromMoment } from './BaseService'
import CollaboratorContractLocationService, {
	CollaboratorContractLocationResponse
} from './CollaboratorContractLocationService'
import CollaboratorContractTypesService, {
	CollaboratorContractTypeResponse
} from './CollaboratorContractTypesService'

export type ResponseCollaboratorContract = {
	id: number
	startDate: string
	endDate?: string
	workload: number
	contractType?: CollaboratorContractTypeResponse
	location: CollaboratorContractLocationResponse
}

type PayloadCollaboratorContract = {
	startDate: string
	endDate?: string
	workload: number
	contractTypeId: number
	locationId: number
}

class CollaboratorContractsService {
	private static model = '/collaborators'

	static from(data: ResponseCollaboratorContract): CollaboratorContract {
		return CollaboratorContractFactory({
			key: data.id,
			startDate: moment(data.startDate),
			workload: data.workload,
			endDate: data.endDate ? moment(data.endDate) : undefined,
			type:
				data.contractType &&
				CollaboratorContractTypesService.from(data.contractType),
			location:
				data.location && CollaboratorContractLocationService.from(data.location)
		})
	}

	static into(data: CollaboratorContract): PayloadCollaboratorContract {
		return {
			startDate: fromMoment(data.startDate),
			endDate: data.endDate ? fromMoment(data.endDate) : undefined,
			workload: data.workload,
			contractTypeId: data.type.key,
			locationId: data.location.key
		}
	}

	static async getAll(id: number): Promise<CollaboratorContract[]> {
		const response = (
			await BaseService.get<ResponseCollaboratorContract[], undefined>(
				`${this.model}/${id}/contracts`,
				undefined
			)
		).data

		return response.map(this.from)
	}

	static async add(
		collaboratorId: number,
		contract: CollaboratorContract
	): Promise<void> {
		await BaseService.post<PayloadCollaboratorContract, void>(
			`${this.model}/${collaboratorId}/contracts`,
			this.into(contract)
		)
	}
	static async update(
		collaboratorId: number,
		contract: CollaboratorContract
	): Promise<void> {
		await BaseService.put<PayloadCollaboratorContract, void>(
			`${this.model}/${collaboratorId}/contracts/${contract.key}`,
			this.into(contract)
		)
	}

	static async delete(
		collaboratorId: number,
		contract: CollaboratorContract
	): Promise<void> {
		await BaseService.remove(
			`${this.model}/${collaboratorId}/contracts/${contract.key}`,
			null
		)
	}

	static async getActiveContract(
		collaboratorId: number
	): Promise<CollaboratorContract | undefined> {
		const contracts = await this.getAll(collaboratorId)
		const today = moment().startOf('day')
		const activeContract = contracts.find(
			c =>
				(today >= c.startDate && c.endDate && today <= c.endDate) ||
				(today >= c.startDate && !c.endDate)
		)
		return activeContract
	}
}
export default CollaboratorContractsService
