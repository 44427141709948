type OidcToken = {
	access_token: string
	expires_at: number
	scope: string
	token_type: string
}

function getToken(): OidcToken {
	const token = localStorage.getItem(
		`oidc.user:${process.env.REACT_APP_API_IDENTITY_SERVER}:js-frontend`
	)
	if (!token) throw new Error('No token found')

	return JSON.parse(token)
}

const pathItem = 'path'

function savePath(path: string) {
	if (path !== '/') localStorage.setItem(pathItem, path)
}

function getLastPath(): string {
	return localStorage.getItem(pathItem) || '/'
}

function saveCollaborator(idCollaborator: string) {
	localStorage.setItem('collaborator', idCollaborator)
}

function getCollaborator(): string {
	return localStorage.getItem('collaborator') || ' '
}

function clearCollaborator() {
	localStorage.removeItem('collaborator')
}

function saveReturnTo() {
	localStorage.setItem('returnTo', '/management/skills')
}

function getReturnTo() {
	return localStorage.getItem('returnTo') || ' '
}

function clearReturnTo() {
	localStorage.removeItem('returnTo')
}

function getProject() {
	return localStorage.getItem('project') || ''
}

function clearProject() {
	localStorage.removeItem('project')
}

export default {
	getToken,
	savePath,
	getLastPath,
	saveCollaborator,
	getCollaborator,
	clearCollaborator,
	saveReturnTo,
	getReturnTo,
	clearReturnTo,
	getProject,
	clearProject
}
