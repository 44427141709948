import ProfileType, { ProfileTypeFactory } from './ProfileType'
import Team from './Team'
import moment, { Moment } from 'moment'
import { JobInfo, Department } from './JobInfo'
import Incomplete from 'Domain/Common/Incomplete'
import { Office } from './Office'
import CollaboratorContract from './CollaboratorContract'
import IListable from 'Domain/Common/IListable'
import { DocumentType } from './CollaboratorDocumentTypes'
import Desk, { DeskFactory } from 'Domain/Entities/Desk'
import CollaboratorMacAddress from './CollaboratorMacAddress'

class Collaborator implements JobInfo, IListable {
	constructor(
		public id: number,
		public key: number,
		public firstName: string,
		public lastName: string,
		public profile: ProfileType,
		public isActive: boolean,
		public allowTimeRegistration: boolean,
		public professionalEmail: string,
		public value: string,
		public personalEmail?: string,
		public birthDate?: Moment,
		public pis?: string,
		public team?: Team,
		public preferredName?: string,
		public manager?: Collaborator,
		public secondaryReviewer?: Collaborator,
		public title?: string,
		public location?: Office,
		public department?: Department,
		public contracts?: CollaboratorContract[],
		public macAddresses?: CollaboratorMacAddress[],
		public fullName?: string,
		public userId?: number,
		public profilePictureUrl?: string,
		public documentType?: DocumentType,
		public documentNumber?: string,
		public desk?: Desk,
		public isLocatedInPortugal?: boolean,
		public isLocatedInBrazil?: boolean
	) {
		this.value = fullName ?? `${firstName} ${lastName}`
		this.isLocatedInPortugal = location
			? location.city.state.country.value === 'Portugal'
			: false
		this.isLocatedInBrazil = location
			? location.city.state.country.value === 'Brazil'
			: false
	}

	isValid() {
		return {
			key: true,
			firstName: !!this.firstName,
			lastName: !!this.lastName,
			professionalEmail: /\S+@\S+.com/.test(this.professionalEmail),
			value: true,
			personalEmail:
				!this.personalEmail || /\S+@\S+.com/.test(this.personalEmail),
			birthDate:
				(this.birthDate && this.birthDate.valueOf() < moment.now()) ||
				!!this.birthDate,
			profile: !!this.profile,
			isActive: true,
			pis: !this.pis || this.pis.match(/^\d{3}\.\d{5}\.\d{2}\-\d{1}$/),
			team: !!this.team,
			preferredName: true,
			manager: true,
			secondaryReviewer: true,
			department: true,
			location: !!this.location,
			title: true,
			allowTimeRegistration: true
		}
	}
}

export function CollaboratorFactory(obj: Partial<Collaborator>): Collaborator {
	return new Collaborator(
		obj.id || 0,
		obj.key || 0,
		obj.firstName || '',
		obj.lastName || '',
		obj.profile || ProfileTypeFactory({}),
		obj.isActive || false,
		obj.allowTimeRegistration || false,
		obj.professionalEmail || '',
		obj.value || '',
		obj.personalEmail || undefined,
		obj.birthDate,
		obj.pis || undefined,
		obj.team,
		obj.preferredName || undefined,
		obj.manager,
		obj.secondaryReviewer,
		obj.title || undefined,
		obj.location,
		obj.department,
		obj.contracts,
		obj.macAddresses || [],
		obj.fullName,
		obj.userId,
		obj.profilePictureUrl,
		obj.documentType || undefined,
		obj.documentNumber || undefined,
		obj.desk || DeskFactory({})
	)
}

export function IncompleteCollaboratorFactory(
	obj: Partial<Collaborator>
): Incomplete<Collaborator> {
	obj.id = undefined
	obj.key = undefined
	obj.firstName = undefined
	obj.lastName = undefined
	obj.professionalEmail = undefined
	obj.value = undefined
	obj.personalEmail = undefined
	obj.birthDate = undefined
	obj.profile = undefined
	obj.isActive = true
	obj.pis = undefined
	obj.team = undefined
	obj.preferredName = undefined
	obj.manager = undefined
	obj.secondaryReviewer = undefined
	obj.title = undefined
	obj.location = undefined
	obj.department = undefined
	obj.contracts = undefined
	obj.fullName = undefined
	obj.allowTimeRegistration = false
	obj.userId = undefined
	obj.profilePictureUrl = undefined
	obj.documentNumber = undefined
	obj.documentType = undefined
	obj.desk = undefined
	obj.macAddresses = undefined
	return Object.setPrototypeOf(obj, Collaborator.prototype)
}

export default Collaborator
