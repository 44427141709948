import React, { useState, useEffect, useRef } from 'react'
import Loader from 'Infra/Loader/Loader'

const SMOOTH_DELAY = 200

function PageTransition() {
	const [Component, setComponent] = useState<JSX.Element | null>(null)
	const mounted = useRef(true)

	useEffect(() => {
		setTimeout(() => {
			if (mounted.current)
				setComponent(
					<div
						style={{
							width: '100%',
							height: '100%',
							position: 'absolute',
							display: 'flex',
							alignItems: 'center'
						}}
					>
						<Loader />
					</div>
				)
		}, SMOOTH_DELAY)

		return () => {
			mounted.current = false
		}
	}, [])

	return Component
}

export default PageTransition
