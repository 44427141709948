import BaseService from './BaseService'
import Team, { TeamFactory } from 'Domain/Entities/Team'

type GetAllResponse = {
	id: number
	name: string
	isActive: boolean
}[]
type TeamPayload = {
	name: string
}
class TeamsService {
	private static model = '/teams'

	static into(data: Team): TeamPayload {
		return { name: data.value }
	}

	static async add(team: Team): Promise<void> {
		await BaseService.post<TeamPayload, void>(this.model, this.into(team))
	}

	static async getAll(): Promise<Team[]> {
		const response = (await BaseService.get<GetAllResponse, void>(this.model))
			.data

		return response.map(e =>
			TeamFactory({
				...e,
				key: e.id,
				value: e.name
			})
		)
	}
}
export default TeamsService
