import IListable from 'Domain/Common/IListable'
import Incomplete from 'Domain/Common/Incomplete'
import { Office, OfficeFactory } from 'Domain/Entities/Office'

export default class Floor implements IListable {
	constructor(
		public key: number,
		public value: string,
		public office: Office
	) {}
}

export function FloorFactory(obj: Partial<Floor>): Floor {
	return new Floor(
		obj.key || 0,
		obj.value || '',
		obj.office || OfficeFactory({})
	)
}

export function IncompleteFloorFactory(obj: Partial<Floor>): Incomplete<Floor> {
	obj.key = undefined
	obj.value = undefined
	obj.office = undefined
	return Object.setPrototypeOf(obj, Floor.prototype)
}
